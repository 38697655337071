<template>
  <div class="useTime">
    <div class="top">
      <div class="topChilid">
        <h1 class="numberH1">
          {{ statistics.dayloginnumber }}
          <span class="sp">个</span>
        </h1>
        <p>今日企业登录人数</p>
      </div>
      <div class="topChilid">
        <h1 class="numberH1">
          {{ statistics.monthloginnumber }}
          <span class="sp">个</span>
        </h1>
        <p>本月企业登录人数</p>
      </div>
      <div class="topChilid">
        <h1 class="numberH1">
          {{ statistics.dayserviceduration }}
          <span class="sp">小时</span>
        </h1>
        <p>今日累计使用</p>
      </div>
      <div class="topChilid">
        <h1 class="numberH1">
          {{ statistics.allserviceduration }}
          <span class="sp">小时</span>
        </h1>
        <p>全员累计使用</p>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom-titile">
        <div class="bottom-title-left">
          <h1>登录日志</h1>
        </div>
        <div class="bottom-title-right">
          <!-- <el-select v-model="value" placeholder="开始时间" clearable>
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"
                            @change="init">
                        </el-option>
                    </el-select>
                    <el-select v-model="value" placeholder="结束时间" clearable>
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"
                            @change="init">
                        </el-option>
                    </el-select> -->
          <el-date-picker
            v-model="value"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="searchList"
          ></el-date-picker>
          <el-input
            v-model="input"
            placeholder="按关键词搜索人员"
            clearable
            @input="searchList"
            @change="searchList"
          ></el-input>
        </div>
      </div>
      <div class="list">
        <el-table
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{ background: '#F2F2F2', textAlign: 'center' }"
        >
          <el-table-column
            prop="user_username"
            label="登录账号"
            width="180"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="user_nickname"
            label="昵称"
            width="180"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="login_lasttime"
            label="最后登录时间"
            align="center"
          ></el-table-column>
          <el-table-column prop="login_ip" label="登录IP" align="center"></el-table-column>
          <el-table-column
            prop="login_duration_str"
            label="登录时长"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="login_all_duration_str"
            label="累计登录时长"
            align="center"
          ></el-table-column>
          <!-- <el-table-column prop="address" label="操作" align="center" width="150">
                        <template slot-scope="scope">
                            <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
                        </template>
                    </el-table-column> -->
        </el-table>
        <div style="text-align: center; margin-top: 25px">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page.page"
            :page-sizes="[10, 15, 30, 35]"
            :page-size="page.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.totalItems"
          ></el-pagination>
        </div>
      </div>
    </div>
    <!-- <div class="dialogList">
            <el-dialog title="查看详情" :visible.sync="dialogVisible" width="45%">
                <el-table :data="tableData" style="width: 100%">
                    <el-table-column prop="date" label="昵称" align="center"></el-table-column>
                    <el-table-column prop="name" label="登录IP" align="center"></el-table-column>
                    <el-table-column prop="address" label="登录时间" align="center"></el-table-column>
                    <el-table-column prop="address" label="登录时长" align="center"></el-table-column>
                </el-table>
                <div style="text-align: center;">
                    <h4 style="cursor: pointer;">加载更多</h4>
                </div>
            </el-dialog>
        </div> -->
  </div>
</template>

<script>
import { getloginloglist, getstatistics } from '../../common/useTime';
export default {
  // 使用时长
  name: 'useTime',
  // 组件参数 接收来自父组件的数据
  props: {},
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {
      value: [],
      input: '',
      tableData: [],
      // 分页
      page: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
      },
      // 详情
      // dialogVisible: false,
      // 统计数据
      statistics: {
        dayloginnumber: 0,
        monthloginnumber: 0,
        dayserviceduration: 0,
        allserviceduration: 0,
      },
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 查看按钮
    // handleClick(e) {
    //     console.log(e);
    //     this.dialogVisible = true
    // },
    searchList() {
      this.page.page = 1;
      this.init();
    },
    // 初始化
    init() {
      let time1;
      let time2;
      if (this.value != '') {
        time1 = +new Date(this.value[0]) / 1000;
        time2 = +new Date(this.value[1]) / 1000;
      } else {
        time1 = '';
        time2 = '';
      }
      let dt = {
        page: this.page.page,
        limit: this.page.pageSize,
        start_time: time1,
        end_time: time2,
        search_keyword: this.input,
      };
      getloginloglist(dt).then((res) => {
        let { code, result } = res;
        if (code == 200) {
          this.tableData = result.data;
          this.page.totalItems = result.total;
        }
      });
      getstatistics().then((res) => {
        let { code, result } = res;
        if (code == 200) {
          this.statistics = result;
        }
      });
    },
    // 分页
    handleSizeChange(size) {
      this.page.pageSize = size;
      this.init();
    },
    handleCurrentChange(page) {
      this.page.page = page;
      this.init();
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.init();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  // 这里存放数据
  beforeCreate() {},
  // 生命周期 - 挂载之前
  beforeMount() {},
  // 生命周期 - 更新之前
  beforeUpdate() {},
  // 生命周期 - 更新之后
  updated() {},
  // 生命周期 - 销毁之前
  beforeDestroy() {},
  // 生命周期 - 销毁完成
  destroyed() {},
  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
};
</script>
<style lang="scss" scoped>
// @import url(); 引入公共css类
.useTime {
  background-color: white;
  // height: calc(100vh - 90px);
  padding: 30px 30px 30px;

  .top {
    display: flex;
    background-color: #f2f2f2;
    padding: 20px 0;

    .topChilid {
      width: 200px;
      text-align: center;

      .numberH1 {
        font-size: 40px;
        margin: 20px 0;

        .sp {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  .bottom {
    .bottom-titile {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .bottom-title-right {
        display: flex;
        min-width: 50%;
        justify-content: right;

        .el-select {
          width: 260px;
          margin: 0 5px;
        }

        .el-input {
          margin-left: 40px;
          width: 300px;
        }
      }
    }
  }

  .dialogList {
    // height: 100px;
    // overflow: hidden;

    /* Firefox */
    scrollbar-width: none;
    /* IE 10+ */
    -ms-overflow-style: none;

    /* Chrome Safari */
    ::-webkit-scrollbar {
      display: none;
    }

    .el-table {
      height: 529px;
      overflow-y: scroll;
    }
  }
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  // width: 100%;
  background-color: #29303e;
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #bbb;
  padding: 15px 0px;
  margin: 0 20px;
}

::v-deep .el-dialog__body {
  padding-top: 0px;
}

::v-deep .el-table::before {
  height: 0;
}
</style>
