import $http from '@/utils/http';

// 列表
export function getloginloglist(data) {
  return $http.get('/api/loginlog/getloginloglist', data, 'loadingDiv');
}

// 统计列表
export function getstatistics(data) {
  return $http.get('/api/loginlog/getstatistics', data, 'loadingDiv');
}
